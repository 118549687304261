.ml-10 {
  margin-left: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.m-0 {
  margin: 0;
}

.mv-10 {
  margin: 10px 0;
}

.mv-25 {
  margin: 25px 0;
}

.m-auto {
  margin: auto;
}

.float-right {
  float: right;
}

.text-center {
  text-align: center;
}
