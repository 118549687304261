.a-discount {
    font-weight: bold;
    font-size: 14px;
    background: $pink-gradient;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5%;
    padding: 2px 5px;
    margin-right: 10px;

    &-container {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        .price {
            opacity: 0.5;
            text-decoration: line-through 1.5px;
            margin-right: 5px;
            font-size: 18px;
        }
    }

    &-valid {
        color: rgba($white, 0.3);
        font-size: 12px;
        float: right;
        margin: 5px 0;
    }
}