.a-check {
    width: 10px;
    height: 5px;
    border: solid $purple-300;
    border-width: 2px 2px 0 0;
    display: inline-block;
    padding: 1px;
    transform: rotate(135deg);
    margin-right: 10px;

    &.-checked {
        border-color: $turquoise;
    }
}