.book-a-package {
    color: $white;

    .package {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // justify-content: space-between;
        font-size: 20px;
        line-height: 26px;
        opacity: 0.9 !important;
        margin-top: 10px;

        &-price {
            text-transform: uppercase;
            font-weight: bold;
            margin-left: 10px;
        }

        .a-discount-container {
            margin-left: 10px;
            line-height: 18px;
            display: flex;
            align-items: center;
            margin: 5px 0 5px 10px;

            .price {
                font-size: 20px;
                text-transform: uppercase;
            }
        }
    }

    .action {
        margin-bottom: 30px;

        .a-btn {
            float: right;
        }
    }
}