.ticket {

    margin-bottom: 30px;

    h1 {
        margin: 5px 0;
    }

    .a-btn {
        margin: 15px 0;
    }

    .m-image-drag-n-drop {
        margin-bottom: 25px;

        @include media-up(m) {
            margin-bottom: 0px;
        }
    }

    select {
        margin-bottom: 25px;
    }
}