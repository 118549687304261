.book-a-service {
    color: $white;

    .service {

        .title {
            display: flex;
            align-items: baseline;
            // align-items: center;
            // justify-content: space-between;
            font-size: 20px;
            line-height: 26px;
            opacity: 0.9 !important;
            margin-top: 10px;
            margin-bottom: 30px;
            flex-wrap: wrap;

            .service-price {
                text-transform: uppercase;
                font-weight: bold;
                margin: 10px 0 10px 10px;
            }

            .a-discount-container {
                margin-left: 10px;
                line-height: 18px;
                display: flex;
                align-items: center;

                .price {
                    font-size: 20px;
                    text-transform: uppercase;
                }
            }
        }

        .a-btn {
            float: right;
        }
    }
}