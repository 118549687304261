.a-logo {
  cursor: pointer;
  background: url("../../../img/backgrounds/logo.png");
  width: 100%;
  min-height: 36px;
  max-width: 110px;
  background-size: 100px auto;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto 10px;

  @include media-up(mobile-s) {
    margin: 0 auto 0 0;

  }

}