.a-progress-ring {
    // transform: rotate(-90deg);
    transform: rotate(-180deg);

    .bg {
        stroke: rgba($white, 0.1);
    }

    .value {
        stroke-linecap: round;
        // stroke: $pink;
    }
}