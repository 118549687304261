.a-breadcrumbs {
    opacity: 0.7;
    cursor: pointer;
    max-width: fit-content;


    &-container {
        display: flex;
        align-items: center;
        max-width: fit-content;
        position: relative;
        padding-right: 20px;

        .m-avatar-chevron {
            position: relative;
        }
    }

}