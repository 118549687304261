$dot-size: 7.76px;

.m-sidenav {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $white;
    padding: 0 15px 0 0;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: $purple-300;
        border-radius: 4px;
        opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
        background: $pink-gradient;
        border-radius: 4px;
    }

    &-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: $white;
        font-size: 14px;
        line-height: 50px;
        font-weight: 400;
        opacity: 0.5;
        margin-bottom: 10px;
        white-space: nowrap;
        transition: opacity .3s;


        @include media-up(s) {
            font-size: 16px;

        }

        &.-active {
            opacity: 1;

            .dot {
                transform: scale(1);
            }
        }

        svg {
            min-width: 24px;
            margin: 0 15px;
            stroke: $white;

            @include media-up(s) {
                margin: 0 30px;

            }
        }
    }
}

.dot {
    background: $pink-gradient;
    height: $dot-size * 2;
    width: $dot-size;
    min-width: $dot-size;
    border-bottom-right-radius: $dot-size * 2;
    border-top-right-radius: $dot-size * 2;
    box-shadow: 0px 0px 10px 8px $pink-01;
    transform: scale(0);
    transition: transform .3s;
}