$white: #ffffff;
$white-015: rgba($white, 0.15);
$white-010: rgba($white, 0.1);
$white-100: #9595A2;

$blue: #698af5;
$blue-100: #fafbfd;
$blue-200: #f4f7fc;
$blue-300: #f1f2f5;
$blue-400: #f5f6fa;
$blue-700: #748aa1;
$blue-800: #788394;
$blue-900: #1c243b;

$gray-100: #fbfbfb;
$gray-200: #e1e1e5;
$gray-300: #d9dfe4;
$gray-400: #d8d8d8;
$gray-500: #7e7d83;
$gray-600: #2c2a34;
$gray-700: #393644;
$gray-800: #0f0b15;
$gray-900: #0d0915;
$black: #000000;

$orange: #fe9800;
$orange-100: #ef8c6c;

$purple: #8185df;
$purple-100: #d6d7e1;
$purple-200: #464459;
$purple-300: #615f7c;
$purple-400: #3d3b51;
$purple-500: #3a384d;
$purple-800: #2b2a44;
$purple-900: #312f47;

$rose-100: #ff8281;
$rose-500: #e35656;

$pink: #ea68a8;
$pink-01: rgba($pink, 0.3);

$turquoise: #29cb97;
$green: #04e474;
$green-yellow: #a5ca3b;

$sidebar-gradient: linear-gradient(135deg, #2e2d48 0%, #201e34 100%);
$sidebar-gradient-reversed: linear-gradient(135deg, #201e34 0%, #2e2d48 100%);
$sidebar-shadow: #1b183f;

$radio-btn: rgb(97, 95, 124);

$pink-gradient: linear-gradient(135deg, #ea68a8 0%, #ef8c6c 100%);
$blue-gradient: linear-gradient(135deg, #8185df 0%, #66d9de 100%);
$background-gradient: radial-gradient(circle, rgba(105, 138, 245, 0.2) 0%, rgba(46, 45, 72, 0) 100%);
$notification-gradient: linear-gradient(135deg, #353c70 0%, #262c55 100%);

$border-color: rgba(255, 255, 255, 0.15);
$border-background-color: rgba(255, 255, 255, 0.1);
$box-shadow: 0 0 20px 0 rgba(27, 24, 63, 0.5);
