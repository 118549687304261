.pagination-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: nowrap;
  color: $white;
  padding: 20px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;

  @include media-up(mobile-s) {
    flex-direction: row;
  }

  .wrap {
    justify-content: space-around;

    @include media-up(mobile-s) {
      justify-content: inherit;

    }

    span,
    div {
      margin: 10px;

      @include media-up(mobile-s) {
        margin: 0;
      }
    }

    span {
      @include media-up(mobile-s) {
        margin-right: 20px;
      }
    }

  }
}

.pagination {
  border-radius: $border-radius-medium;
  border: 1px solid $border-color;
  background-color: $border-background-color;
  margin: 20px auto 0;

  @include media-up(mobile-s) {
    margin: 0;
  }

  .item {
    cursor: pointer;
    color: $white;
    float: left;
    padding: 8px 16px;
    border-radius: $border-radius-medium;
    text-decoration: none;
    user-select: none;

    &:hover {
      background: $purple-900;
    }

    &.active {
      background: $pink-gradient;
      color: $white;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.select-page {
  background: transparent;
  border: 1px solid $border-color;
  border-radius: $border-radius-medium;
  background-color: $border-background-color;
  margin: 0 10px;
  color: $white;

  & option {
    background: $purple-900;
  }
}