.m-button-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba($white, .1);
    border: 1px solid rgba($white, .15);
    border-radius: 35px;

    overflow-x: auto;



    div {
        height: 42px;
        display: flex;
        align-items: center;
    }

    @include media-up (mobile-s) {
        flex-direction: row;

    }

    &.-regular {
        padding: 20px 12px;
        flex-direction: row;

        // @include media-up (mobile-s) {
        //     flex-direction: row;

        //     div {
        //         &:not(:first-child) {
        //             margin-top: 0;
        //         }
        //     }
        // }


        // div {
        //     &:not(:first-child) {
        //         margin-top: 0;

        //         @include media-up(mobile-s) {
        //             margin-top: 0;

        //         }
        //     }


        // }

        input {
            -webkit-appearance: none;
        }

        label {
            padding: 13px 20px;
            border-radius: 30px;
            background-color: transparent;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.3px;
            white-space: nowrap;
            color: $white;
            opacity: 0.3;
            cursor: pointer;

            @include media-up(m) {
                font-size: 17px;
            }

            span {
                font-weight: bold;
                letter-spacing: normal;
                margin-left: 10px;
                text-transform: uppercase;
            }
        }

        input:checked+label {
            background: $blue-gradient;
            color: $white;
            opacity: 1;
        }
    }



    &.-mini {
        padding: 5px 18px;
        overflow: initial;
        flex-direction: column;

        @include media-up (m) {
            flex-direction: row;
    
        }

        input {
            -webkit-appearance: none;
        }

        label {
            padding: 8px 20px;
            border-radius: 30px;
            background-color: transparent;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.3px;
            white-space: nowrap;
            color: $white;
            opacity: 0.3;
            cursor: pointer;
        }

        input:checked+label {
            background: $blue-gradient;
            color: $white;
            opacity: 1;
        }
    }

}