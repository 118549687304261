.m-selectSearch {
    min-width: 235px;
    width: fit-content;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
    background-color: $purple-300;
    border-radius: 4px;
    opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
    background: $pink-gradient;
    border-radius: 4px;
    }

    input {
        padding: 0 20px 0 15px;
        height: 44px;
        font-size: 13px;
    }

    &__select {

        &.-open {
            outline: 0;
            border-color: $pink;

            ~.select-arrow {
                border-color: $pink;
                transform: rotate(-135deg);
                top: 21px;
            }
        }

        &.-hide {
            color: transparent;

            &::placeholder {
                visibility: hidden;
            }
        }

        &.-error {
            border-color: red;
        }
    }

    &__options {
        width: 100%;
        position: relative;
        border: 1px solid $purple-300;
        border-radius: $border-radius-medium;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: $purple-900;
        margin-top: -5px;
        z-index: 1;
        min-height: fit-content;
        max-height: 135px;
        overflow-y: auto;
        display: none;
        top: 5px;

        &.-open {
            opacity: 1;
            display: block;
            pointer-events: initial;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 5px 10px;
                font-size: 13px;
                cursor: pointer;
                transition: background-color .3s;
                color: $white;
                margin: 0 !important;

                &:hover {
                    background-color: rgba(#ffffff, 0.2);
                }
            }
        }
    }

    &__arrow {
        width: 6px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: 10px;
        cursor: pointer;
        border: 1px solid silver;
        border-width: 0 2px 2px 0;
        padding: 2px;
        transform: translateY(-50%) rotate(225deg);
        transition: transform .3s, border-color .3s;
    }

    &__searchInput {
        width: 100%;
        background-color: transparent;
        outline: 0;
        border: 2px solid transparent;
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 0;
        color: $white;
    }


}