.language {
  position: relative;
  width: 26px;
  margin: 0 7px;
  color: $white;
  cursor: pointer;


  @include media-up(mobile-m) {
    margin: 0 10px;
  }

  .value {
    color: $purple-100;
    font-size: 13px;


    @include media-up(mobile-m) {
      font-size: 14px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 40px;
    border-radius: $border-radius-extra-big;
    background: $notification-gradient;
    box-shadow: $box-shadow;
    padding: 20px 30px 20px 20px;
    min-width: 120px;
    z-index: 1;

    &-item {
      cursor: pointer;
      display: flex;
      color: $purple-100;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 30px;

      // & img {
      //   width: 20px;
      //   margin-right: 10px;
      //   margin-left: 5px;
      // }
    }
  }
}