.booking {

    color: $white;

    .header {
        margin-bottom: 30px;

        h1 {
            margin: 5px 0;
        }
    }

    .btn {
        button {
            float: right;
        }
    }

    .title {
        color: $white;
        margin-bottom: 10px;
    }

    &-user {
        .info {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 30px;

            @include media-up(mobile-m) {
                align-items: center;
                flex-direction: row;
            }

            .img-name {
                display: flex;
                align-items: center;

                @include media-up(mobile-m) {
                    margin-right: 50px;

                }

                .image {
                    width: 26px;
                    min-width: 26px;
                    height: 26px;

                    &.-service-plan {
                        width: 40px;
                        min-width: 40px;
                        height: 40px;
                    }

                    &.-user {
                        width: 40px;
                        min-width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }
                }

                .none {
                    opacity: 0;
                }

                .name {
                    font-weight: 500;

                    @include media-up(mobile-m) {
                        white-space: nowrap;

                        margin-left: 10px;
                    }
                }
            }

            .email-title {
                color: rgba($white, 0.6);
                margin-top: 10px;

                @include media-up(mobile-m) {
                    margin-top: 0;
                }

            }

            .email {
                font-weight: 500;

                @include media-up(mobile-m) {

                    margin-left: 10px;
                }
            }

            .status {
                background-color: $white-100;
                border-radius: 22px;
                padding: 10px 15px;
                text-transform: uppercase;
                font-size: 10px;
                line-height: 13px;
                letter-spacing: 2px;
            }
        }
    }

    &-items {

        .items {
            display: flex;
            flex-direction: column;

            .item-container {
                background: $sidebar-gradient-reversed;
                box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
                border-radius: $border-radius-extra-big;
                color: $white;
                padding: 20px;
                margin-top: 30px;

                .status {
                    background-color: rgba($white-100, 0.1);
                    border-radius: $border-radius-extra-big;
                    padding: 10px 15px;
                    text-transform: uppercase;
                    font-size: 10px;
                    line-height: 13px;
                    letter-spacing: 2px;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .edit {
                        cursor: pointer;
                    }
                }
            }

            .item {
                display: flex;
                flex-direction: column;
                // margin-top: 30px;
                // background: $sidebar-gradient-reversed;
                // box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
                // border-radius: $border-radius-extra-big;
                // color: $white;
                // padding: 20px;

                @include media-up(mobile-m) {
                    align-items: center;
                    flex-direction: row;

                    justify-content: space-between;

                }

                .image {
                    width: 70px;
                    min-width: 70px;
                    height: 70px;
                    border-radius: 5px;
                }

                .info {
                    display: flex;
                    flex-direction: column;

                    @include media-up(mobile-s) {
                        flex-direction: row;
                        align-items: center;

                    }
                }

                .info-container {
                    display: flex;
                    flex-direction: column;
                    margin: 15px 0 0 0;

                    @include media-up(mobile-s) {
                        margin: 0 0 0 30px;
                    }


                    .name {
                        margin-bottom: 15px;
                    }

                    .row {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: rgba($white, 0.6);

                        .date-time {
                            margin: 5px 0;
                            color: $white;

                            @include media-up(mobile-s) {
                                margin: 0 0 0 10px;
                            }

                        }
                    }
                }

                .price-container {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;

                    @include media-up(mobile-s) {
                        margin-top: 0;
                    }

                    .price-row,
                    .price-discount-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: rgba($white, 0.6);
                        padding: 5px 15px;

                        .price,
                        .price-discount {
                            margin-left: 10px;
                            color: $white;
                        }
                    }

                    .price-discount-row {
                        border-top: 1px solid rgba($white, 0.6);
                    }
                }
            }
        }
    }

    &-created {
        margin-top: 20px;

        .created-container {
            float: right;
            margin-right: 15px;
            color: rgba($white, 0.6);
        }
    }
}