.m-shopping-cart {
    display: flex;
    flex-direction: column;
    background: $notification-gradient;
    border-radius: $border-radius-extra-big;
    padding: 10px;
    color: $white;
    margin-top: 30px;

    &-list {
        list-style: none;
        padding: 0;
        // width: 100%;

        .package-name {
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;

            span {
                font-weight: 400;
                margin-left: 10px;
            }
        }

        .package-addition {
            opacity: 0.8;
            margin-right: 10px;
        }

        .from {
            margin-top: 10px;
        }

        .starting-from {
            opacity: 0.5;
            margin-right: 10px;
        }

        .date-time {
            font-size: 14px;
            line-height: 18px;
            white-space: nowrap;
        }

        li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            &:not(:first-child) {
                margin-top: 15px;
            }

            .item-name {
                display: flex;
                flex-wrap: wrap;
            }

            .from {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 10px;
                margin-left: 0;

                .date-time {
                    margin: 5px 0;
                }
            }

            .currency {
                text-transform: uppercase;
            }

            @include media-up(s) {
                flex-direction: row;
                align-items: center;

                .from {
                    margin-top: 0;
                    margin-left: 30px;
                }
            }

            .remove {
                cursor: pointer;
                margin-right: 10px;
                opacity: 0.5;

                // &:hover {
                //     opacity: 1;
                // }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 5px 10px 9px 10px;

        @include media-up(l) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .content-action {
            display: flex;
            align-items: center;
            width: 100%;

            flex-direction: column;
            align-items: center;

            @include media-up(l) {
                width: auto;
            }

            @include media-up(mobile-s) {
                flex-direction: row;
                justify-content: flex-end;
            }

            .currency {
                font-size: 24px;
                line-height: 26px;
                font-weight: bold;
                margin: 0 0 15px 0;
                white-space: nowrap;
                text-transform: uppercase;

                @include media-up(mobile-s) {
                    margin: 0 13px 0 0;
                }
            }

            .a-btn {
                margin: 0;
            }
        }
    }

    .description {
        background-color: rgba($white, .1);
        padding: 14px;
        text-align: center;
        margin-top: 9px;
        border-radius: $border-radius-medium;
        font-size: 13px;
        line-height: 17px;
        font-style: italic;
        opacity: 0.7;
    }
}