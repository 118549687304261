.m-active-package {
    color: $white;
    background: $sidebar-gradient-reversed;
    border-radius: $border-radius-extra-big;
    box-shadow: 0 30px 20px -10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin: 0 10px 25px;
    display: flex;
    flex-direction: column;


    .info {
        width: 100%;
    }

    .title {
        color: $blue-300;
        opacity: 0.5;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 2px;
        margin-bottom: 10px;
    }

    .package-name {
        font-size: 18px;
        line-height: 24px;
        color: $blue-300;
        margin-bottom: 5px;
    }

    .start-date {
        color: $blue-300;
        opacity: 0.7;
        font-size: 13px;
        line-height: 19px;

        span {
            margin-right: 5px;
        }
    }

    .progress {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;


        .days-left {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            text-align: center;

            .days {
                font-size: 40px;
                line-height: 40px;
                color: $blue-300;
            }

            .label {
                color: $blue-300;
                opacity: 0.6;
                font-weight: 500;
                text-transform: uppercase;
                // white-space: nowrap;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 2px;
            }

        }
    }

    .active-addition {
        margin-bottom: 15px;
        display: flex;

        .image-container {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            background-color: rgba($white, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            .image {
                width: 30px;
                height: 30px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
        }

        .name,
        .status {
            font-size: 14px;
            line-height: 20px;
        }

        .status {
            opacity: 0.5;
        }
    }
}