$fonts-directory: "../../fonts";

@font-face {
  font-family: 'Red Hat Display';
  src: url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Medium.eot');
  src: url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Medium.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Medium.woff2') format('woff2'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Medium.woff') format('woff'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Bold.eot');
  src: url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Bold.woff2') format('woff2'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Bold.woff') format('woff'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Regular.eot');
  src: url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Regular.woff2') format('woff2'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Regular.woff') format('woff'),
      url('#{$fonts-directory}/RedHatDisplay/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
