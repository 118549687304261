.a-input-group {
  position: relative;
  padding-bottom: 20px;
  // max-width: 500px;

  label {
    // label as a placeholder

    display: inline-block;
    position: absolute;
    cursor: text;
    transition: top .3s, left .3s, font-size .3s, line-height .3s, padding .3s;


    &.-dark {
      top: 13px;
      left: 13px;
      font-size: 14px;
      color: $white;
    }

    &.-light {
      top: 14px;
      left: 15px;
      font-size: 13px;
      color: $gray-800;
    }

    &.-required {
      &::after {
        content: "*";
        color: $pink;
        margin-left: 1px;
      }
    }

    &.-hidden {
      display: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  .a-input+label {
    cursor: text;
  }

  .a-select+label {
    cursor: default;
  }

  .a-input:focus+label,
  .a-select:focus+label,
  .-active+label,
  .-filled+label {
    // move label to the top

    display: inline-block;
    position: absolute;
    top: -7px;
    left: 12px;
    font-size: 10px;
    line-height: 13px;
    padding: 0px 5px;
    z-index: 1;

    &::after {
      content: "";
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      z-index: -1;
    }

    &.-dark {
      color: $white;

      &::after {
        border-top: 3px solid $purple-900;
      }
    }

    &.-light {
      color: $gray-800;

      &::after {
        border-top: 3px solid $gray-100;
      }
    }
  }

  .a-input:disabled,
  .a-select:disabled {
    border-color: $gray-700;
    color: $gray-400;
    cursor: not-allowed;
  }

  &.-textarea {
    height: 100%;
    max-width: 100%;
  }

  .a-select:focus+label+.select-arrow {
    border-color: $pink;
    transform: rotate(-135deg);
    top: 21px;
  }

  &-date {
    position: relative;
    width: 100%;
    // min-width: 250px;
    max-width: 500px;

    color: $white;
  }

  &-time {
    position: relative;
    width: 100%;
    // min-width: 250px;
    color: $white;
  }
}

.a-input,
.a-select {
  width: 100%;
  transition: border-color .3s;
  box-shadow: none;
  filter: none;
  min-height: 49px;



  &::placeholder {
    color: $white;
    visibility: hidden;
  }

  &:focus {
    border: 1px solid $pink;
    outline: 0;
  }

  &:focus::-webkit-input-placeholder {
    visibility: hidden;
  }

  &-icon {
    position: absolute;
    top: 12px;
    right: 5px;
  }

  &-loader {
    position: absolute;
    top: 12px;
    right: 15px;
  }

  &.-datetime {
    background-color: $purple-400;
    white-space: nowrap;


    &.-active {
      border-color: $pink;
    }

    .datetime-value {
      visibility: hidden;
    }

    &.-filled {
      .datetime-value {
        visibility: visible;
      }
    }


  }

  &.-time {
    position: absolute;
    z-index: 2;
    top: 60px;
    background-color: $purple-400;
    width: 100%;
    border: 1px solid $purple-300;
    border-radius: $border-radius-medium;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .space {
      margin: 0 10px;
      font-weight: 500;
    }

    .am-pm {
      background-color: rgba($white, 0.1);
      border: 1px solid $purple-300;
      border-radius: $border-radius-medium;
      width: 100%;
      padding: 5px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        -webkit-appearance: none;
      }

      input {
        -webkit-appearance: none;
      }

      label {
        padding: 5px;
        width: 50%;
        text-align: center;
        background: transparent;
        border-radius: $border-radius-medium;
        font-size: 16px;
        line-height: 21px;
        cursor: pointer;
      }

      input:checked+label {
        background: $pink-gradient;
      }
    }

    .select-time-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 12px 0;
    }

    .select-time {
      display: flex;
      flex-direction: column;
      align-items: center;

      .more,
      .less {
        width: 24px;
        height: 24px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .more::before,
      .less::before {
        content: "";
        width: 10px;
        height: 10px;
        background: $pink-gradient;
      }

      .more::after,
      .less::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background: $purple-400;
      }

      .more::before {
        transform: rotate(45deg);
        margin-bottom: 10px;
      }

      .less::before {
        transform: rotate(-135deg);
        margin-top: 10px;
      }

      .more::after {
        top: 4.5px;
        transform: rotate(45deg);
      }

      .less::after {
        transform: rotate(-135deg);
        top: 9.5px;
      }

      label {
        margin-right: 5px;
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      input,
      select {
        width: 70px;
        height: 40px;
        border-radius: $border-radius-medium;
        display: block;
        background-color: rgba($white, 0.1);
        border: 1px solid $purple-300;
        color: $white;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
      }

      input:focus {
        outline: none;
      }
    }
  }

  &.-dark {
    background-color: $purple-900;
    border: 1px solid $purple-300;
    border-radius: $border-radius-medium;
    color: $white;
    font-size: 14px;
    padding: 13px;

    &::placeholder {
      color: $white;
      visibility: hidden;
    }

    &:focus {
      border: 1px solid $pink;
      outline: 0;
    }

    &.-error {
      border-color: $rose-500;
    }
  }

  &-error {
    color: $rose-500;
    font-size: 12px;
    line-height: 18px;

    position: absolute;
    top: 50px;
    left: 15px;
  }

  &.-light {
    background-color: $gray-100;
    border: 1px solid $gray-300;
    border-radius: 2px;
    color: $gray-800;
    font-size: 13px;
    padding: 14px 15px;

    &::placeholder {
      color: $gray-800;
      visibility: hidden;
    }

    &:focus {
      border: 1px solid $pink;
      outline: 0;
    }

    &.-error {
      border-color: $rose-500;
    }
  }

  &.-hasIcon {
    padding-right: 34px;
  }
}

.a-select {
  -webkit-appearance: none;
}

.select-arrow {
  pointer-events: none;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 19px;
  right: 13px;
  cursor: pointer;
  border: 1px solid $purple-300;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
}

textarea.a-input {
  height: 100%;
  resize: none;
}

.date-time {
  align-items: center;

  &-input {
    margin-bottom: 15px;

    @include media-up(s) {
      margin-bottom: 0;
    }

  }



  .a-btn {
    float: right;

    margin-top: 15px;
  }
}

.date-time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 60px;
  border: 1px solid #615f7c;
  border-radius: 5px;
  background-color: #3d3b51;
  z-index: 2;

  .m-calendar-input,
  .a-input.-time {
    position: relative;
    top: 0px;
    border: none;
  }
}

.a-input-group-date {
  // width: max-content;
  width: 100%;
}

div.a-input.-datetime {

  &.-dark,
  &.-light {

    min-width: 171px;
    font-size: 13px;

    @include media-up(mobile-m) {
      min-width: 200px;
    }

    &.-active,
    &.-filled {

      +label {
        font-size: 10px;
        top: -7px;
        left: 12px;
      }
    }


    +label {
      font-size: 12px;
      left: 10px;
      top: 16px;
    }
  }

  &.-light {
    @include media-up(m) {
      min-width: 180px;
    }
  }
}

.auth-container {
  .a-input-group-date {
    width: 100%;
  }
}