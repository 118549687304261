.m-multiselect {
  color: $white;
  position: relative;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: $purple-300;
    border-radius: 4px;
    opacity: 0.5;
  }

  ::-webkit-scrollbar-thumb {
    background: $pink-gradient;
    border-radius: 4px;
  }

  .focus {
    border-color: $pink !important;
    border-bottom: hidden !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .error {
    border-color: $rose-500 !important;
  }

  .options-container {
    padding: 10px 12px 6px 12px;
    width: 100%;
    position: relative;
    border: 1px solid $pink;
    border-top: hidden;
    border-radius: $border-radius-medium;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $purple-900;
    margin-top: -5px;
    z-index: 1;
  }

  .multiselect {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $purple-900;
    border: 1px solid $purple-300;
    border-radius: $border-radius-medium;
    color: $white;
    font-size: 14px;
    line-height: 18px;
    padding: 13px;
    user-select: none;
    // cursor: text;

    // &:focus-within {
    //     border: 1px solid $pink;
    //     border-bottom: hidden;
    //     border-bottom-left-radius: 0;
    //     border-bottom-right-radius: 0;
    // }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &-btn {
      width: 6px;
      height: 6px;
      cursor: pointer;
      border: 1px solid $purple-300;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      transform: rotate(45deg);

      &.-up {
        border-color: $pink;
        transform: rotate(-135deg);
      }

      &.-down {
        border-color: $purple-300;
        transform: rotate(45deg);
      }
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      span.search {
        outline: none;
        color: $white;
        align-self: center;
        min-width: 20px;

        input {
          width: 50px;
        }
      }
    }

    &-search-item {
      display: flex;
      margin: 0px 3px 3px 0px;
    }

    &-item {
      background: $blue-gradient;
      border-radius: $border-radius-medium;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px 5px 5px 0px;
      color: $white;

      .label {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        margin-left: 8px;
      }

      .remove {
        height: 10px;
        position: relative;
        width: 10px;
        justify-content: center;
        display: flex;
        align-items: center;
        transform: rotate(-45deg);
        margin-left: 4px;
        cursor: pointer;

        &::after,
        &::before {
          background: $white;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &::before {
          width: 1px;
          height: 100%;
        }

        &::after {
          width: 100%;
          height: 1px;
        }
      }
    }

    &-options {
      max-height: 100px;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 14px;
      line-height: 30px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;

          .check {
            width: 10px;
            height: 5px;
            border: solid $turquoise;
            border-width: 2px 2px 0 0;
            display: inline-block;
            padding: 1px;
            transform: rotate(135deg);
            margin-right: 10px;
          }

          &.-disable {
            cursor: not-allowed;

            .check {
              border-color: $purple-300;
            }
          }
        }
      }
    }
  }
}