/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 21;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding-bottom: 20px;

  &.-admin {
    .modal-content {
      overflow-y: auto;
    }
  }

  &-container {
    position: relative;
    margin: 0 30px;
    background: $sidebar-gradient-reversed;
    box-shadow: 0 30px 20px -10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 21;

    @include media-down(mobile-xs) { 
      max-width: 90vw;
    }
  }

  .a-input,
  .a-select {
    min-width: 210px;

  }

  .a-input {
    &.-time {
      min-width: 0;
    }
  }

  &.show {
    display: flex;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: $purple-300;
    border-radius: 4px;
    opacity: 0.5;
  }

  ::-webkit-scrollbar-thumb {
    background: $pink-gradient;
    border-radius: 4px;
  }

  .close {
    position: absolute;
    top: -25px;
    right: -25px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $purple-500;
    color: $white;
    border: 5px solid rgba($black, 0.7);
    border-radius: 50%;
    font-size: 24px;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    z-index: 2;
  }
}

.modal-header {
  margin-bottom: 30px;

  & .title {
    opacity: 0.9;
    color: $white;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 34px;
  }
}

/* Modal Content */
.modal-content {
  position: relative;
  padding: 30px 25px 15px;
  margin: 10px 0;
  border-radius: 15px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;

  max-height: 90vh;
  overflow: auto;

  @include media-up(mobile-m) {
    overflow: visible;
  }


  /* The Close Button */
  & .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

//   /* Modal Content */
.modal-special-content {
  position: relative;
  color: $white;
  background: $sidebar-gradient;
  margin: auto;
  padding: 40px 25px;
  width: 100%;
  max-height: 80vh;
  border-radius: 10px;
  box-shadow: $box-shadow;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;

  @include media-up(mobile-s) {
    padding: 40px;
  }

}

.modal-body {
  padding: 2px 5px;
}

.horizontal-line {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid $purple-300;
  opacity: 0.3;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .a-btn {
    margin: 0;
  }
}

.modal-special-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $notification-gradient;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 40px;

  @include media-up(mobile-m) {
    flex-direction: row;
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    // flex-wrap: wrap;

    // @include media-up(mobile-m) {
    //   flex-wrap: nowrap;
    // }

    @include media-up(mobile-m) {
      flex-direction: row;
      margin-top: 0;
    }

  }

  .title {
    font-size: 20px;
    line-height: calc(26/24);
    letter-spacing: -0.5px;



    @include media-up(mobile-s) {
      padding-right: 20px;
    }

    @include media-up(xs) {
      font-size: 24px;

    }

  }

  .currency {
    font-size: 20px;
    line-height: calc(26/24);
    font-weight: bold;
    margin-right: 0;
    text-transform: uppercase;
    white-space: nowrap;

    @include media-up(mobile-s) {
      margin-right: 20px;
    }


    @include media-up(xs) {
      font-size: 24px;

    }
  }

  .a-discount-container {
    margin-right: 20px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .price {
      font-size: 24px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .a-discount {
      white-space: nowrap;
    }
  }
}