.catalog {
  &-container {
    // height: 100vh;
  }

  &-item {
    height: 25vh;
    box-sizing: border-box;
    border: 1px solid $gray-400;
    border-radius: $border-radius-big;
    background-color: $white;
    box-shadow: 0 1px 4px 0 $gray-400;
  }
}
