.event-invitation {
    padding: 50px;
    background: $sidebar-gradient-reversed;
    box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius-extra-big;
    color: $white;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;

    button {
        margin-bottom: 20px;
    }
}