.alert {
  position: fixed;
  display: flex;
  align-items: center;
  right: 10px;
  top: 20px;
  padding: 5px;
  color: $white;
  z-index: 30;
  transition: opacity 0.6s;
  border-radius: 20px;
  background: $notification-gradient;
  box-shadow: $box-shadow;

  &-content {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin-left: 20px;
    margin-right: 20px;
    color: $white;
    letter-spacing: 0;

    &-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 21px;
    }

    &-message {
      margin-top: 5px;
      opacity: 0.8;
      font-size: 12px;
      line-height: 22px;
    }
  }
}