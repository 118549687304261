.m-calendar {
  position: relative;
  width: 100%;


  .body {
    .cell {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      background: $white;
      color: $black;
      user-select: none;

      &:hover {
        border: 1px solid $white;
      }

      .number {
        position: absolute;
      }
    }

    .disabled {
      pointer-events: none;
    }
  }

  .select {
    padding: 5px;
    font-size: 16px;
    background: transparent;
    color: $black;
    border: none;
  }

  &.-big {
    .header {
      padding: 1.5em 0;
      font-size: 115%;
      color: $black;

      .icon {
        cursor: pointer;
        transition: 0.15s ease-out;

        &:hover {
          transform: scale(1.75);
          transition: 0.25s ease-out;
          color: $black;
        }
      }
    }

    .days {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 16px;
      color: $gray-700;
      user-select: none;
    }

    .body {
      .cell {
        border-radius: $border-radius-big;
        margin: 0.5em;
        min-height: 5em;
        background: $white;
        color: $black;

        .number {
          font-size: 14px;
          line-height: 16px;
          top: 0.75em;
          right: 0.75em;
        }
      }

      .selected {
        background-color: $black;
        color: $white;
      }

      .disabled {
        color: $gray-600;
        // Calcite??
        background-color: $white;
      }

      // calculate days in calendar minus margin on cells x2
      .col {
        flex-grow: 0;
        flex-basis: calc(100% / 7 - 1em);
        width: calc(100% / 7 - 1em);
      }
    }

    .left,
    .right {
      width: 24px;
      height: 24px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .left::before,
    .right::before {
      content: "";
      width: 10px;
      height: 10px;
      background: $pink-gradient;
      transform: rotate(45deg);
    }

    .left::after,
    .right::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: $purple-400;
      transform: rotate(45deg);
    }

    .left::before {
      margin-right: 10px;
    }

    .right::before {
      margin-left: 10px;
    }

    .left::after {
      right: 9px;
    }

    .right::after {
      left: 9px;
    }
  }

  &.-small {
    // position: absolute;
    // min-height: 235px;
    // bottom: 0;
    padding: 0 15px 25px 15px;
    margin-top: auto;

    .header {
      // padding: 0.5em;
      margin-bottom: 15px;
      color: $white;

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-title {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: $white;
      }

      &-date {
        display: flex;
        align-items: center;
      }

      .title {
        font-size: 24px;
        line-height: 34px;
      }

      .month-title {
        font-size: 12px;
        line-height: 16px;
        margin-right: 5px;
      }

      .icon {
        color: $blue-700;

        svg {
          width: 14px;
          height: 14px;
        }

        //   &:hover {
        //     transform: scale(1.5);
        //     transition: 0.25s ease-out;
        //     color: $white;
        //   }
      }

      .select {
        padding: 0;
        color: $white;
        font-size: 12px;
        line-height: 16px;
        -webkit-appearance: none;
      }

      .select-option {
        background-color: $purple-400;
        color: $white;
      }
    }

    .days {
      font-size: 12px;
      line-height: 16px;
      user-select: none;
      margin-bottom: 8px;
      color: $blue-700;
    }

    .body {
      .cell {
        color: $white;
        background-color: rgba($blue-200, 0.1);
        height: 30px;
        width: 30px;
        margin: 2px;
        border-radius: $border-radius-small;

        .number {
          font-size: 10px;
          line-height: 13px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          border: 1px solid $purple;
        }
      }

      .selected {
        background: $blue-gradient;
      }

      .disabled {
        color: $blue-800;
      }

      // calculate days in calendar minus margin on cells x2
      .col {
        flex-grow: 0;
        flex-basis: calc(100% / 7 - 4px);
        width: calc(100% / 7 - 4px);
      }
    }

    .left,
    .right {
      width: 10px;
      height: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .left::before,
    .right::before {
      content: "";
      width: 5px;
      height: 5px;
      border-top: 1px solid $blue-700;
      border-left: 1px solid $blue-700;
    }

    .left::before {
      margin-right: 5px;
      transform: rotate(-45deg);
    }

    .right::before {
      margin-left: 5px;
      transform: rotate(135deg);
    }
  }

  &-input {
    min-height: 262px;
    position: absolute;
    z-index: 2;
    top: 60px;
    background-color: $purple-400;
    border: 1px solid $purple-300;
    border-radius: $border-radius-medium;
    padding: 15px 12px 14px 6px;
    width: 100%;

    .header {
      margin-bottom: 15px;
      color: $white;

      &-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-date {
        display: flex;
        align-items: center;
      }

      .month-title {
        font-size: 14px;
        line-height: 18px;
        margin-right: 5px;
      }

      .icon {
        color: $blue-700;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .select {
        cursor: pointer;
        padding: 0;
        color: $white;
        font-size: 14px;
        line-height: 18px;
        -webkit-appearance: none;
      }

      .select-option {
        background-color: $purple-400;
        color: $white;
      }
    }

    .days {
      font-size: 12px;
      line-height: 16px;
      color: $white;
      margin-bottom: 8px;
    }

    .body {
      .cell {
        color: $white;
        background-color: $purple-400;
        min-height: 1.5em;
        margin: 2px;
        border-radius: $border-radius-medium;

        .number {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          line-height: 16px;
        }

        &:hover {
          border: 1px solid $purple;
        }
      }

      .selected {
        background: $blue-gradient;
      }

      .disabled {
        color: $gray-500;
      }

      // calculate days in calendar minus margin on cells x2
      .col {
        flex-grow: 0;
        flex-basis: calc(100% / 7 - 4px);
        width: calc(100% / 7 - 4px);
      }
    }

    .left,
    .right {
      width: 24px;
      height: 24px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .left::before,
    .right::before {
      content: "";
      width: 10px;
      height: 10px;
      background: $pink-gradient;
      transform: rotate(45deg);
    }

    .left::after,
    .right::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: $purple-400;
      transform: rotate(45deg);
    }

    .left::before {
      margin-right: 10px;
    }

    .right::before {
      margin-left: 10px;
    }

    .left::after {
      right: 9px;
    }

    .right::after {
      left: 9px;
    }
  }
}