.m-image-drag-n-drop {
    // max-width: 500px;
    background-color: $purple-900;
    border: 1px dashed $purple-300;
    border-radius: 5px;
    color: $white;
    font-size: 14px;
    line-height: 18px;
    padding: 15px;
    margin-top: 25px;
    transition: border-color .3s;
    cursor: pointer;

    &:hover {
        border-color: $pink;
    }

    .dropzone {
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        flex-wrap: wrap;

        @include media-up(mobile-s) {
            justify-content: initial;
        }

        .a-image {
            margin-right: 0;

            @include media-up(mobile-s) {
                margin-right: 15px;

            }
        }
    }

    .image {
        background-color: rgba($white, .1);
        width: 70px;
        height: 70px;
        border-radius: 50%;
        margin-right: 15px;


        img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
        }
    }

    .text {
        margin: 10px auto;
        padding: 0 10px;

        @include media-up(mobile-s) {
            padding: 0;
        }

        @include media-up(mobile-m) {

            margin: 0;
        }

        p {
            margin-bottom: 5px;
        }

        span {
            size: 13px;
            line-height: 22px;
            letter-spacing: -0.2px;
            text-decoration: underline;
            font-style: italic;
            cursor: pointer;
            margin-top: 5px;
        }
    }

    .file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        margin-right: 20px;
    }
}