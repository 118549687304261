.event {

    margin-bottom: 30px;

    h1 {
        margin: 5px 0;
    }

    button {
        float: right;
    }

    .m-image-drag-n-drop {
        margin-bottom: 25px;

        @include media-up(m) {
            margin-bottom: 0px;
        }
    }

    &-fields {
        color: $white;

        @include media-up(m) {
            max-width: none;
        }

        .m-image-drag-n-drop {
            margin-bottom: 25px;

            @include media-up(m) {
                margin-bottom: 0px;
            }
        }

        .title {
            margin-bottom: 30px;
        }

        .booking-item {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            flex-wrap: wrap-reverse;
            background: $sidebar-gradient-reversed;
            box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
            border-radius: $border-radius-extra-big;
            padding: 20px;


            .date-time-container {

                // margin-top: 30px;
                // width: 250px;
                margin: 0 10px;

                .row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // flex-wrap: nowrap;
                    color: rgba($white, 0.6);
                    margin-bottom: 5px;
                    white-space: nowrap;


                    .date-time {
                        margin-left: 10px;
                        white-space: nowrap;
                        // color: $white;
                    }
                }

            }

            img {
                width: 225px;
                height: 150px;
                border-radius: 10px;
            }

            .title {
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        .invitation {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
        }

        .invitation-description {
            font-size: 13px;
            line-height: 22px;
            opacity: 0.7;
            margin-top: 5px;
            margin-bottom: 20px;
        }

        .m-array-field {

            .invitation-field {
                display: flex;
                align-items: center;

                button {
                    margin-left: 50px;
                }

                .status {
                    background-color: rgba($white, 0.1);
                    border-radius: 20px;
                    padding: 11px 24px;
                    text-transform: uppercase;
                    font-size: 10px;
                    line-height: 13px;
                    letter-spacing: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    margin-left: 50px;
                    min-width: 150px;
                }
            }
        }

        .guests {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .guest {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 10px;
                flex-direction: column;

                &:not(:first-child) {
                    border-top: 1px solid rgba($white, .2);
                    padding-top: 10px;
                }

                @include media-up(mobile-s) {
                    align-items: center;
                    flex-direction: row;
                }

                .status {
                    background-color: rgba($white, 0.1);
                    border-radius: 20px;
                    padding: 11px 24px;
                    text-transform: uppercase;
                    font-size: 10px;
                    line-height: 13px;
                    letter-spacing: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    min-width: 150px;
                    max-width: 150px;
                    margin: 15px 0;

                    @include media-up (mobile-s) {

                        margin: 15px auto 15px auto;
                    }

                    @include media-up(mobile-m) {
                        margin: 15px 0px 15px 50px;
                    }
                }

                .email {
                    background-color: $purple-900;
                    border: 1px solid $gray-700;
                    color: $gray-400;
                    cursor: not-allowed;
                    border-radius: 5px;
                    font-size: 14px;
                    padding: 13px;
                    min-width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @include media-up(mobile-xs) {
                        min-width: 235px;

                    }
                }
            }


        }

        .max-persons {
            opacity: 0.5;
            font-size: 12px;
            margin-left: 10px;
        }
    }

    &-date-time {
        .m-date-and-time {
            &-title {
                margin-top: 0px;
            }
        }
    }

    &-btn {
        float: right;
    }
}