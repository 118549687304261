h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: $white;
  letter-spacing: normal;
  opacity: 1;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h4,
h5,
h6 {
  opacity: 0.7;
}

h1 {
  font-weight: 500;
  font-size: 26px;
  line-height: calc(40/26);
  opacity: 0.9;

  @include media-up(mobile-s) {
    font-size: 30px;
  }
}

h2 {
  font-size: 18px;
  line-height: calc(24/20);

  @include media-up(mobile-s) {
    font-size: 20px;
  }

}

h3 {
  font-size: 16px;
  line-height: calc(24/18);


  @include media-up(mobile-s) {
    font-size: 18px;
  }
}

h4 {
  font-size: 14px;
  line-height: 14px;

}

h5 {
  font-size: 13px;
  line-height: 22px;
}

h6 {
  font-size: 12px;
  line-height: 20px;
}

p {
  font-weight: 400;
  letter-spacing: normal;
  color: $white;
  margin: 0;

  &.-big {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &.-regular {
    font-size: 14px;
    line-height: 24px;
  }

  &.-small {
    font-size: 12px;
    line-height: 16px;
  }
}

.highlight-info {
  font-size: 12px;
  line-height: 14px;
  color: $gray-500;
  font-style: italic;
}

.bold {
  font-weight: 600;
}