.m-date-and-time {

    &-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 2px;
    }

    &-description {
        font-size: 13px;
        line-height: 22px;
        opacity: 0.7;
        margin-bottom: 20px;
    }
}