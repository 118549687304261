@mixin createBtn($background, $color: $white) {
  background-color: $background;
  color: $color;

  &:hover {
    background-color: lighten($background, 10%);
  }
}

.a-btn {
  display: inline-flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  color: $white;
  font-weight: 500;
  transition: 0.2s;
  border: 1px solid transparent;
  border-radius: 22px;
  font-size: 13px;
  line-height: 17px;
  background-color: rgba($white, 0.1);
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $pink-gradient;
  }

  &.-primary {
    background-color: rgba($white, 0.1);
    border: 1px solid transparent;
  }

  &.-secondary {
    background-color: transparent;
    border: 1px solid $pink;
  }

  &.-filled {
    background: $pink-gradient;
  }

  // &.-disabled {
  //   background: $purple-300;

  //   &:hover {
  //     background-color: rgba($white, 0.1) !important;
  //     cursor: not-allowed;
  //   }
  // }

  &.-rounded {
    padding: 10px;
    margin-left: 20px;
    width: 38px;
    height: 38px;
  }

  &.-disabled {
    opacity: 0.4;

    &:hover {
      background: none;
      background-color: rgba($white, 0.1);
      cursor: auto;
    }
  }

  &.-small {
    padding: 8px 22px;
  }

  &.-regular {
    padding: 11px 24px;
  }

  &.-icon {
    font-weight: 400;
  }
}

.btn-group {
  &-container {
    display: flex;
    align-items: center;
    color: $white;
    text-align: center;
    border-radius: 35px;
    box-sizing: border-box;
    border: 1px solid $border-color;
    background-color: $border-background-color;
    margin: 40px;
    padding: 8px 24px;
  }

  &-item {
    cursor: pointer;
    width: 33%;
    opacity: 0.3;
    color: $white;
    font-size: 18px;
    letter-spacing: -0.35px;
    line-height: 20px;

    &.-active {
      opacity: 1;
      padding: 13px 20px;
      border-radius: 30px;
      background: $blue-gradient;
    }
  }
}

.btn-cancel {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  color: $white;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 18px;
  margin-right: 20px;
  padding: 13px 0;
}