.m-avatar {
  &-container {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }

  &-initials {
    font-size: 15px;
    line-height: 50px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;

    @include media-up(mobile-m) {
      font-size: 18px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;

    &.-name {
      font-size: 13px;
      line-height: 16px;
      color: $white;
      white-space: nowrap;
      margin-bottom: 5px;
      max-width: 50px;
      white-space: break-spaces;

      @include media-up(mobile-xs) {
        white-space: initial;
      }

      @include media-up(mobile-s) {

        max-width: none;
      }

      @include media-up(mobile-m) {
        font-size: 14px;
      }
    }

    &.-title {
      font-size: 12px;
      line-height: 14px;
      color: $white;
      opacity: 0.6;
    }

    &.-creator {
      margin-bottom: 5px;
      white-space: nowrap;
    }
  }

  &-chevron {
    position: absolute;
    right: 0;
    border: 1.5px solid;
    border-width: 0 1.5px 1.5px 0;
    width: 5px;
    height: 5px;
    // margin: 0 20px 0 10px;
    cursor: pointer;
    transition: transform .3s, border-color .3s;


    @include media-up(mobile-m) {
      // margin: 0 32px 0 10px;
    }

    &.-up {
      border-color: $rose-100;
      transform: rotate(225deg);
    }

    &.-down {
      border-color: $purple-300;
      transform: rotate(45deg);
    }

    &.-left {
      transform: rotate(135deg);
      margin-left: 0;
      margin-right: 5px;
      width: 7px;
      height: 7px;
      border: 2px solid $purple-300;
      border-width: 0 2px 2px 0;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 30px;
    border-radius: $border-radius-extra-big;
    background: $notification-gradient;
    box-shadow: $box-shadow;
    padding: 20px 30px 20px 20px;
    min-width: 120px;
    z-index: 9;
    opacity: 0;
    pointer-events: none;
    transition: top .3s, opacity .3s;

    &.-active {
      opacity: 1;
      top: 50px;
      pointer-events: initial;
    }

    &-item {
      color: $purple-100;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 30px;
      white-space: nowrap;

      & svg {
        margin-right: 10px;
      }
    }
  }

  &:hover {
    svg {
      color: #2980b9;
    }
  }
}