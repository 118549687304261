.m-card-group {

    .card-group-title {
        font-size: 13px;
        line-height: 22px;
        opacity: 0.7;
    }

    ul {
        padding: 0;
        list-style-type: none;
        list-style: none;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;

        // @include media-up(xl) {
        //     justify-content: space-between;
        // }
    }

    input {
        -webkit-appearance: none;
    }

    label {
        display: flex;
        align-items: center;
        background-color: $purple-900;
        border: 1px solid $purple-300;
        border-radius: $border-radius-extra-big;
        width: 255px;
        padding: 19px;
        color: $white;
        cursor: pointer;

        .radio {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            background-color: $purple-400;
            border: 1px solid $purple-300;
            margin-right: 20px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: transparent;
            }
        }

        .up-to {
            opacity: 0.6;
            margin-top: 6px;
            margin-bottom: 10px;
        }

        .price {
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
        }
    }

    input:checked+label{
        border: 1px solid $pink;

        .radio {
            border-color: $pink;

            &::after {
                background: $pink-gradient;
            }
        }
    }
}