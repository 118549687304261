.additional-options {
    &-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        text-align: center;
    }

    &-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include media-up(s) {
            flex-direction: row;
        }

        img {
            width: 132px;
            height: 120px;

            @include media-up(s) {
                margin-right: 30px;
            }
        }

        .content {
            width: 100%;
        }

        .info-action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;
            flex-direction: column;
            margin: 15px 0 25px 0;

            @include media-up(s) {
                flex-direction: row;
                margin-top: 0;
            }


            .a-btn {
                margin: 15px 0 0 0;

                @include media-up(s) {
                    margin: 0 0 0 15px;
                }

            }

            ;

            .info {
                display: flex;
                flex-direction: column;

                .name {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }

                .price {
                    display: flex;

                    .period {
                        opacity: 0.7;
                        font-weight: 400;
                        margin-left: 5px;
                    }

                    .value {
                        text-transform: uppercase;
                    }
                }

                .a-discount-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 5px;

                    .price {
                        font-size: 16px;
                        text-transform: uppercase;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}