.a-checkbox {
  // height: 100%;
  display: flex;
  align-items: center;

  .checkbox {
    position: absolute;
    opacity: 0;

    &.-dark {
      &+label {
        color: $white;
      }

      &+label:before {
        background-color: $purple-400;
        border-color: $purple-300;
      }
    }

    &.-light {
      &+label {
        color: $gray-600;
        font-size: 12px;
        line-height: 16px;
      }

      &+label:before {
        background-color: $gray-100;
        border-color: $gray-200;
      }
    }

    &+label {
      position: relative;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      display: flex;
      align-items: center;
    }

    &+label:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      min-width: 18px;
      border: 1px solid $purple-300;
      border-radius: $border-radius-small;
      background-color: $purple-400;
      box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.05);

    }

    &:hover+label:before {
      border-color: $rose-100;
    }

    &:checked+label:before {
      background: $pink-gradient;
    }

    &:disabled+label {
      color: $gray-400;
      cursor: auto;
    }

    &:disabled+label:before {
      box-shadow: none;
      background: $gray-400;
    }

    &:checked+label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 50%;
      background: $white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white, 4px -4px 0 $white, 4px -6px 0 $white, 4px -8px 0 $white;
      transform: translateY(-50%) rotate(45deg);
    }
  }

  & .error {
    &+label {
      color: $rose-500 !important;
    }

    &+label:before {
      border-color: $rose-500 !important;
    }
  }
}