.no-auth-container {
  .language-container {
    position: fixed;
    right: 0;
    padding: 10px;
  }

  .no-auth-background {
    background: url("../../img/backgrounds/VRS_0926-stock-optimised.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    order: 2;
    min-height: 100vh;

    @include media-up(s) {
      order: 1;
      height: 100vh;
    }
  }

  .no-auth-form {
    order: 1;
    min-height: 70vh;

    @include media-up(s) {
      order: 2;
      min-height: 100vh;
    }
  }

  .no-auth-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    min-height: 80%;
    max-width: 420px;
    margin: 0 auto;

  }

  .no-auth-breadcrumbs {
    font-size: 16px;
    line-height: 30px;
    color: $purple-300;
    display: flex;
    align-items: center;
    padding: 30px;

    i {
      opacity: 0.5;
    }
  }

  .auth-container {
    padding: 40px 15px;



  }

  .image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100px;
    width: 100px;
    margin: 0 auto;


    &.-forgot-password {
      background-image: url("../../img/icons/19-\ lock-square.svg");
    }
  }

  h1 {
    color: $gray-900;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
  }

  h2 {
    color: $gray-500;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
  }

  a {
    color: $gray-600;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  .info-message {
    color: $gray-700;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 17px;
  }

  .forgot-password-info-message {
    color: $purple-300;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.main-container {
  display: flex;
  background: url("../../img/backgrounds/top\ gradient.png") no-repeat fixed,
    url("../../img/backgrounds/mesh\ bottom.png") 0px 380px no-repeat fixed, $sidebar-gradient;
  background-size: contain;
  min-height: 100vh;
  overflow-y: auto;
}

.wrap-container {
  width: 100%;

  @include media-up(s) {
    margin: 0 280px 0 270px;
    max-width: 1440px;

  }

  // max-width: none;
}


.additionsBooking {
  display: flex;
  flex-direction: column;
}

.notValidDate{
  color: red;
}

.content-container {
  width: 100%;
  padding: 130px 40px 30px 90px;

  &.-user {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;



    @include media-up(mobile-m) {
      flex-direction: row;

    }

    .title {
      p {
        opacity: 0.7;
        margin-top: 5px;
      }
    }

  }

  .a-btn {
    margin-top: 15px;

    @include media-up(s) {
      margin-top: 0;
    }
  }

}

.user-dashboard {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-container {
    height: 100%;
  }

  .user-bookings {
    height: 70%;
    overflow-y: auto;
  }
}

.user-dashboard-container {
  height: 100%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: $purple-300;
    border-radius: 4px;
    opacity: 0.5;
  }

  ::-webkit-scrollbar-thumb {
    background: $pink-gradient;
    border-radius: 4px;
  }

  .user-bookings {
    position: absolute;
    background: $sidebar-gradient-reversed;
    top: 111px;
    right: 0;
    transform: translateX(100%);
    transition: transform .3s;

    @include media-up(l) {
      transform: translateX(0);

    }

    @include media-up(mobile-s) {
      top: 66px;

    }

    &.-open {
      transform: translateX(0);


      .user-bookings-opener {
        background-color: #201e34;

        &:hover {

          &::after {
            transform: translate(-50%, -50%) rotate(-45deg) scale(1.2);

          }
        }

        &::after {
          border-color: $pink;

          transform: translate(-50%, -50%) rotate(-45deg);



        }
      }

    }


    &-container {
      overflow-y: auto;
      height: calc(100vh - 111px);
      width: 240px;
      padding: 35px 5px;

      @include media-up(mobile-s) {
        height: calc(100vh - 66px);
        width: 285px;
        padding: 35px 20px;

      }


    }


    &-opener {
      display: block;
      position: absolute;
      left: 0;
      top: 10px;
      transform: translateX(-100%);
      width: 30px;
      height: 60px;
      background-color: $pink;
      border-radius: 60px 0 0 60px;
      border-right: transparent;
      cursor: pointer;
      transition: background-color .3s;


      @include media-up(l) {
        display: none;
      }




      &:hover {

        &::after {
          transform: translate(0, -50%) rotate(135deg) scale(1.2);
        }
      }


      // &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 15px;
        border: 2px solid #201e34;
        border-top: transparent;
        border-left: transparent;
        transform: translate(0, -50%) rotate(135deg);
        transition: transform .3s, border-width .3s;
      }


      // &::before {
      //   transform: translate(-50%, -50%) rotate(0deg);
      // }


    }
  }

  .m-avatar-info {
    &.-name {
      max-width: 120px;

      @include media-up(mobile-s) {
        max-width: none;
      }
    }
  }
}

.event-invitation-container {
  display: flex;
  align-items: center;
  justify-content: center;
}