.service-plan-form {
    .m-image-drag-n-drop {
        margin-top: 0;
    }
}

.price-form, .service-item-form {
    .default {
        font-size: 14px;
        opacity: 0.7;
        margin-top: 5px;

        .value {
            text-transform: uppercase;
            margin-left: 5px;
        }
    }
}