.m-list {
  &-container {
    padding: 0;
    list-style-type: none;
    list-style: none;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;

    &.-column {
      flex-direction: column;
    }
  }

  &.-ticket {
    background-color: $purple-400;
    color: $white;
    border-radius: 10px;
    padding: 20px 15px;
    margin-bottom: 30px;

    @include media-up(mobile-s) {
      padding: 20px 30px;
    }

    .a-input-group.-textarea {
      height: 100%;
      max-width: 100%;
  
      height: 150px;
  
      @include media-up(mobile-s) {
        height: initial;
      }
    }

    .ticket-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;

      @include media-up(mobile-s) {
        flex-wrap: nowrap;
      }

      &-title {
        display: flex;
        flex-direction: column;
        padding-right: 10px;

        .date-label,
        .category {
          font-size: 13px;
          line-height: 24px;
          opacity: 0.7;
        }

        .category-name {
          font-size: 13px;
          line-height: 24px;
        }

        .subject-label {
          font-size: 18px;
          line-height: 26px;
          font-weight: 500;

          .status {
            font-size: 12px;
            line-height: 24px;
            font-weight: 400;
            display: inline-block;
            margin-left: 10px;

            & .-badge-open {
              color: $green;
            }

            & .-badge-in_progress {
              color: $orange;
            }

            & .-badge-closed {
              color: $gray-400;
            }
          }
        }
      }

      &-creator {
        display: flex;
        align-items: center;
        margin-top: 10px;
        width: 100%;
        justify-content: space-between;

        @include media-up(mobile-xs) {
          width: initial;
        }

        @include media-up(mobile-s) {
          margin-top: 0;
        }

        .m-avatar {
          &-container {
            flex-direction: column;
            align-items: initial;

            @include media-up(mobile-xs) {
              flex-direction: row;
              align-items: inherit;
            }
          }

          &-info {

            &.-title {
              margin-top: 10px;

              @include media-up(mobile-xs) {
                margin-top: inherit;
              }
            }

            &.-name {
              line-height: 1.4;

              @include media-up(mobile-xs) {
                line-height: inherit;

              }
            }
          }
        }

        .a-btn {
          margin: 0 0 0 20px;
        }
      }
    }

    .ticket-middle {
      display: flex;
      flex-direction: column;

      &-description {
        margin-bottom: 30px;
      }

      &-comments {
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        @include media-up(mobile-s) {
          margin-left: 30px;
        }

        .comments-item {
          background-color: rgba($blue-100, 0.1);
          border: 1px, solid, $gray-200;
          border-radius: $border-radius-small;
          padding: 15px;
          margin-bottom: 20px;

          @include media-up(mobile-s) {
            padding: 20px 30px 20px 20px;

          }

          .comment-creator {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            @include media-up(mobile-s) {
              justify-content: space-between;
              align-items: center;

              flex-direction: row;
            }

            .m-avatar {

              &-container {
                flex-direction: column;
                align-items: initial;

                @include media-up(mobile-s) {
                  flex-direction: row;
                  align-items: inherit;
                }


              }

              &-info {
                &.-name {
                  margin-top: 15px;

                  @include media-up(mobile-s) {
                    margin-top: inherit;
                  }

                  @include media-up(mobile-xs) {
                    white-space: nowrap;
                  }
                }
              }
            }

            .in-reply {
              display: inline-block;
              margin-top: 5px;

              @include media-up(mobile-s) {
                margin-top: inherit;
              }
            }
          }

          .in-reply {
            font-size: 12px;
            line-height: 16px;
            font-style: italic;
          }
        }
      }
    }

    .ticket-bottom {
      .show-details {
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &.-package-container {
    display: flex;
    justify-content: center;
  }

  &.-package {
    width: 255px;
    padding: 20px;
    background: $sidebar-gradient-reversed;
    box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
    border-radius: $border-radius-extra-big;
    color: $white;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;



    &.-small {
      padding: 28px 20px;

      .title {
        // margin-bottom: 20px;

        .period {
          font-size: 12px;
          line-height: 20px;
          opacity: 0.7;
        }
      }

      .actions {
        margin-top: 20px;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;

      h2 {
        &:first-child {
          margin-right: 5px;
        }
      }

    }

    .a-discount-container {
      margin-top: 10px;
    }

    .price {
      white-space: nowrap;
      text-transform: uppercase;
    }

    .sublist {
      padding: 0;
      margin: 30px 0;
      list-style-type: none;
      list-style: none;

      &-title {
        display: inline;
        margin-left: 10px;
        opacity: 0.8;
      }

      li:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      // &.-space {
      //   // justify-content: space-between;
      // }

      &.-end {
        justify-content: flex-end;
      }

      .seemore {
        cursor: pointer;
        // white-space: nowrap;
        margin-left: 10px;
        // color: $white;
        // font-size: 12px;
        // line-height: 16px;
        // opacity: 0.7;
      }
    }

  }

  &.-addition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: $purple-400;
    border-radius: $border-radius-medium;
    border: 1px solid transparent;
    padding: 24px 10px;
    margin-bottom: 25px;
    color: $white;
    transition: border-color .3s;

    @include media-up(mobile-xs) {
      padding: 24px 20px;
    }

    @include media-up(mobile-m) {
      align-items: flex-end;
      padding: 24px 30px;
    }

    @include media-up(l) {
      flex-direction: row;
      align-items: center;
    }

    &:hover {
      border-color: $pink;
    }

    &.-disable {
      background-color: rgba($purple-400, 0.5);

      &:hover {
        border-color: transparent;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;

      @include media-up(mobile-m) {
        flex-direction: row;
      }

      @include media-up(l) {
        flex-direction: row;
        width: 70%;
        margin-bottom: 0;
      }

      @include media-up(m) {
        flex-direction: row;
      }

      img {
        width: 70px;
        height: 70px;
        min-width: 70px;

        @include media-up(mobile-m) {
          margin-right: 20px;

        }
      }

      .description {
        width: 100%;
        margin-top: 20px;

        @include media-up(m) {
          margin: 0 0 0 30px;
        }

        .a-input {
          &.-datetime {
            background-color: $purple-200;
          }

        }

        .a-input-group {
          label {
            &.-dark {
              &::after {
                border-color: $purple-200;
              }
            }
          }
        }
      }

      .message {
        font-size: 16px;
        line-height: 24px;
        opacity: 0.7;
        font-style: italic;
        margin-top: 5px;
      }

      .price-container {
        margin: 5px 0 15px;
      }

      .price {
        display: flex;

        .value {
          text-transform: uppercase;
        }

        .period {
          opacity: 0.7;
          margin-left: 5px;
        }
      }

      .input-group-date {
        width: 200px;
      }

      .a-discount-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 5px;

        .price {
          font-size: 16px;
          text-transform: uppercase;
          margin-right: 5px;
        }
      }

      .a-discount-valid {
        margin-left: 10px;
      }
    }

    &-options {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: $purple-400;
      border-radius: $border-radius-medium;
      border: 1px solid transparent;
      padding: 20px;
      margin-bottom: 25px;
      color: $white;

      @include media-up(mobile-m) {
        flex-direction: row;
      }

      @include media-up(s) {
        flex-direction: column;
      }

      @include media-up(l) {
        flex-direction: row;
      }

      &:hover {
        border-color: $pink;
      }

      &.-disable {
        background-color: rgba($purple-400, 0.5);

        &:hover {
          border-color: transparent;
        }
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;

        @include media-up(xs) {
          flex-direction: row;
          margin-bottom: 0;
        }

        @include media-up(s) {
          flex-direction: column;
          margin-bottom: 15px;
        }

        @include media-up(l) {
          flex-direction: row;
          margin-bottom: 0;
        }

        img {
          width: 60px;
          height: 60px;
        }

        .description {
          margin-top: 15px;

          @include media-up (mobile-m) {
            margin: 0 0 0 20px;
          }

          @include media-up(s) {
            margin: 15px 0 0 0;
          }

          @include media-up(l) {
            margin: 0 0 0 20px;

          }
        }

        .message {
          font-size: 16px;
          line-height: 24px;
          opacity: 0.7;
          font-style: italic;
          margin-top: 5px;
          // white-space: nowrap;
        }



        .price {
          display: flex;
          flex-wrap: nowrap;
          margin: 8px 0 0 0;

          .price-value {
            white-space: nowrap;
            text-transform: uppercase;
          }

          .period {
            opacity: 0.7;
            margin-left: 5px;
            white-space: nowrap;
          }
        }

        .input-group-date {
          width: 200px;

        }

        .a-discount-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .price {
            font-size: 16px;
            text-transform: uppercase;
            margin-right: 5px;
          }
        }

        .a-discount-valid {
          float: left;
        }
      }
    }
  }

  &.-service {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $sidebar-gradient-reversed;
    border-radius: $border-radius-extra-big;
    box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
    color: $white;
    padding: 15px 12px;
    margin-bottom: 30px;

    @media screen and (min-width: 1140px) {
      flex-direction: row;
    }

    img {
      width: 225px;
      height: 150px;
      border-radius: 10px;
    }

    .item {
      padding: 4px 0;
      text-align: center;

      @media screen and (min-width: 1140px) {
        text-align: left;
        padding: 4px 0 4px 30px;
      }

      .description {
        opacity: 0.8;
        margin-top: 10px;
        margin-bottom: 20px;

        @media screen and (min-width: 1140px) {
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }

      .title-price-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 15px;


        @media screen and (min-width: 1140px) {
          margin-top: 0;
        }

        h2 {
          white-space: nowrap;
          margin-right: 10px;
        }

        .price {
          text-transform: uppercase;
          white-space: nowrap;
          font-size: 20px;
          // margin-left: 10px;
        }

        .a-discount-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-left: 10px;
          white-space: nowrap;

          .price {
            font-size: 20px;
            text-transform: uppercase;
            margin-right: 5px;
          }
        }

        .price-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
    }
  }

  &.-event {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: $sidebar-gradient-reversed;
    border-radius: $border-radius-extra-big;
    box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
    color: $white;
    padding: 15px 20px 15px 15px;
    margin-bottom: 30px;

    @include media-up(mobile-s) {
      align-items: flex-start;

    }

    @media screen and (min-width: 1290px) {
      flex-direction: row;
    }

    img {
      width: 220px;
      min-width: 170px;
      height: 170px;
      border-radius: 15px;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-up(mobile-s) {
        flex-direction: row;
        align-items: initial;
      }

      // @media screen and (min-width: 1110px) {
      //   flex-direction: row;
      // }
    }

    .info {
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;

      @include media-up(mobile-s) {

        margin-top: 0;
        text-align: left;
        padding: 9px 0 9px 30px;
      }

      h2 {
        white-space: nowrap;
      }

      .date-time {
        font-size: 13px;
        line-height: 19px;
        margin-top: 10px;
        display: flex;
        align-items: center;

        svg {
          min-width: 16px;
        }
      }

      .date {
        margin-left: 8px;
      }

      .time {
        opacity: 0.7;
        margin-left: 10px;
      }

      .invite {
        font-size: 13px;
        line-height: 19px;
        margin-top: 20px;
        cursor: pointer;
        width: fit-content;
      }

      .guests {
        margin-top: 26px;
        display: flex;
      }

      .all-guests {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-right: 5px;
        background: $blue-gradient;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: default;

        &-container {
          display: flex;
          align-items: center;

          .see-all {
            font-size: 14px;
            opacity: 0.5;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }

      .answers {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;

        @include media-up(mobile-s) {
          flex-direction: row;
        }

        .a-btn {
          &:nth-child(2) {
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }

      .answer-response {
        font-size: 14px;

        .response {
          margin-right: 10px;
          opacity: 0.7;
        }

        .change-answer {
          cursor: pointer;
          text-decoration: underline;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .status {
      background-color: rgba($white, 0.1);
      border-radius: 20px;
      padding: 10px 15px;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 2px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
    }
  }
}

.addition-sublist {

  @include media-up(xxs) {
    column-count: 2;
  }

  @include media-up(m) {
    @include text-columns;

  }

  padding: 0;
  list-style-type: none;
  list-style: none;
  margin: 40px 0;

  p {
    display: inline;
    margin-left: 10px;
  }

  li:not(:last-child) {
    margin-bottom: 10px;
  }
}