.m-radio-group {
  display: flex;
  flex-direction: column;

  &.-dark {
    color: $white;
    background-color: $purple-900;
    border: 1px solid $purple-300;
    border-radius: 5px;
    padding: 13px;
    // margin-top: 25px;
  }

  &.-light {
    color: $gray-800;
  }

  &-options {
    display: flex;
    flex-wrap: wrap;

    .a-radio {
      margin: 5px 0;
    }
  }

  label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}