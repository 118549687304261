.a-line {
    height: 1px;
    width: 100%;
    opacity: 0.1;
    border: 1px solid $white;
    display: inline-block;
    margin: 35px 0;

    &.-mini {
        width: 95%;
        margin: 0;
    }
}

.activePackageTitle {
    margin-bottom: 10px;
}

.reniewNote {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: white;
    padding-bottom: 30px;
}