.a-image {

  &-container {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-regular {
      border: 1px solid silver;
      height: 160px;
      width: 160px;
    }

    &-avatar {
      // cursor: pointer;
      // position: relative;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      height: 160px;
      width: 160px;
    }

    &-user-upload {
      // border: 1px solid silver;
      height: 160px;
      width: 160px;
    }

    &-guest {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: rgba($white, 0.1);
      color: rgba($white, 0.5);
      font-size: 14px;
      text-transform: uppercase;
      cursor: default;
    }
  }

  &.-regular {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 20px;
    margin-right: 15px;
    background: none;

    &.-show {
      background: url('../../../../assets/img/icons/drag&drop-illustration.svg');
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.-avatar {
    width: 30px;
    height: 30px;

    border-radius: $border-radius-big;
    text-align: center;
    user-select: none;
    margin-right: 10px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-up(mobile-m) {
      height: 34px;
      width: 34px;

    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius-big;
    }

    .text {
      font-size: 13px;
      color: $black;

      @include media-up(mobile-m) {
        font-size: 16px;
      }
    }
  }

  &.-user-upload {
    // border: 1px solid silver;
    height: 160px;
    width: 160px;
    position: relative;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }

    .image-icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -8px;
      right: -8px;
      width: 40px;
      height: 40px;
      border-radius: $border-radius-extra-big;
      background: $pink-gradient;
      border: 3px solid $purple-900;
    }

    .text {
      font-size: 36px;
    }
  }

}