.navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 111px;
  background: transparent;
  padding: 15px;
  padding: 7px 15px 7px;
  z-index: 20;
  background: $sidebar-gradient;
  box-shadow: 0 0 20px 0 rgba($sidebar-shadow, .5);


  @include media-up(mobile-s) {
    flex-direction: row;
    padding: 15px 15px 15px 20px;
    min-height: 0;
  }

  @include media-up(s) {
    padding-left: 35px;

  }
}

.topnav-no-auth {
  float: right;
  padding: 5px;
}