.sidebar {
    height: 100%;
    min-height: 100vh;
    background: $sidebar-gradient;
    box-shadow: 0 25px 20px 0 rgba($sidebar-shadow, .5);
    position: fixed;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding-top: 111px;
    z-index: 20;

    @include media-up(mobile-s) {
        padding-top: 66px;
    }

    &.-left {
        // overflow: auto;
        width: 60px;
        transition: width .3s;

        @include media-up(s) {
            min-width: 270px;

        }

        &.-open {
            width: 220px;

        }



        .sidebar-main {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: $purple-300;
                border-radius: 4px;
                opacity: 0.5;
            }

            &::-webkit-scrollbar-thumb {
                background: $pink-gradient;
                border-radius: 4px;
            }
        }
    }

    &.-right {
        width: 285px;
        padding: 35px 20px;
        right: 0;
    }


    .sidebar-left-opener {
        display: block;
        position: absolute;
        right: 0;
        top: 121px;
        transform: translateX(100%);
        width: 15px;
        min-width: 15px;
        height: 30px;
        border-radius: 0 30px 30px 0;
        background: $pink;
        cursor: pointer;
        transition: background-color .3s, transform .3s;

        @include media-up(mobile-s) {
            top: 76px;
        }

        @include media-up(s) {
            display: none;
        }

        &.-open {

            &::after {
                transform: translate(-50%, -50%) rotate(225deg);

            }
        }



        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(calc(-50% - 3px), -50%) rotate(45deg);
            left: 50%;
            height: 8px;
            width: 8px;
            border: 2px solid #201e34;
            border-left: 0;
            border-bottom: 0;
            transition: transform .3s;

        }
    }

    .m-calendar {
        display: none;

        @include media-up(s) {
            display: block;
        }
    }
}