.m-notification {
  &-container {
    position: relative;
  }

  &-icon {
    width: 30px;
    height: 30px;
    background-color: rgba($white, 0.1);
    border-radius: $border-radius-medium;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    // overflow: hidden;
    color: $white;
    transition: background-color .3s;

    svg {
      width: 22px;
      height: 22px;

      @include media-up(mobile-m) {
        width: 24px;
        height: 24px;
      }

    }

    @include media-up(mobile-m) {
      width: 36px;
      height: 36px;



    }

    &:hover {
      background-color: $pink;
    }

    &.-show {
      &::before {
        content: "";
        display: block;
        background: $pink-gradient;
        position: absolute;
        top: -3px;
        right: -3px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: 2px solid $purple-800;
      }
    }

    &.-active {
      background-image: $pink-gradient;
    }
  }

  &-badge {
    position: absolute;
    height: 14px;
    top: -8px;
    right: 12px;
    border-radius: 50%;
    background: #e74c3c;
    color: $white;
    width: 14px;
    text-align: center;
    line-height: 14px;
    font-size: 9px;
    font-weight: 700;
  }

  &-list {
    position: absolute;
    right: 2px;
    margin-top: 10px;
    background: $notification-gradient;
    list-style: none;
    padding: 20px;
    color: $white;
    border-radius: $border-radius-extra-big;
    box-shadow: 0 0 20px 0 rgba(27, 24, 63, 0.5);
    display: flex;
    flex-direction: column;
    z-index: 1;
    opacity: 0;
    top: 20px;
    pointer-events: none;
    transition: top .3s, opacity .3s;

    &.-active {
      opacity: 1;
      top: 36px;
      pointer-events: initial;
    }

    &-item {
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;

      &:not(:first-child)::before {
        content: "";
        height: 1px;
        width: 100%;
        border: 1px solid $white;
        opacity: 0.1;
        position: absolute;
        top: -15px;
      }

      p {
        color: $purple-100;
      }

      &-title {
        font-size: 14px;
        font-weight: 600;
      }

      &-description {
        font-size: 12px;
        line-height: 16px;
      }

      &-date {
        font-size: 10px;
        line-height: 14px;
        opacity: 0.8;
        float: right;
        margin-top: 5px;
      }
    }

    a {
      text-decoration: none;
      color: $white;
      white-space: nowrap;
    }
  }
}