.a-radio {
  display: flex;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
  }

  label {
    display: flex;
    cursor: pointer;
    margin: 0 20px 0 0 !important;

    .radio {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.05);

      &::after {
        content: "";
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: transparent;
      }

      &.-dark {
        background-color: $purple-400;
        border: 1px solid $purple-300;
      }

      &.-light {
        background-color: $gray-100;
        border: 1px solid $gray-300;
      }
    }

    .label {
      font-size: 14px;
      line-height: 18px;
    }
  }

  input:checked+label {
    .radio {
      border-color: $pink;

      &::after {
        background: $pink-gradient;
      }

      &.-dark {
        &::after {
          border: 1px solid $purple-400;
        }
      }

      &.-light {
        &::after {
          border: 1px solid $gray-100;
        }
      }
    }
  }
}