table {
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
}



.table {
  position: relative;
  border-radius: $border-radius-extra-big;
  background: $sidebar-gradient;
  box-shadow: $box-shadow;
  color: $white;
  padding: 30px;
  margin-top: 50px;
  overflow-x: auto;
  width: 100%;
  z-index: 0;

  &-empty {
    width: 100%;

    td {
      width: 100%;
      text-align: center;
    }
  }
}

.table-header-item {
  padding-bottom: 10px;

  svg {
    margin-left: 5px;
  }
}

th {
  cursor: pointer;
  padding: 5px;

}

td {
  white-space: nowrap;
  padding: 5px;
  border-bottom: 1px solid rgba(116, 138, 161, 0.2);

  & svg {
    cursor: pointer;
  }

  .td-div {
    flex-wrap: nowrap;

    h4,
    p {
      padding: 0 5px;
    }
  }
}

.table-search-field {
  max-width: 200px;
  padding: 10px 20px;
  border-radius: 21px;
  margin: 0 5px;
  border: 1px solid $border-color;
  background-color: $border-background-color;

  @include media-up(mobile-s) {
    max-width: none;
    margin-right: 20px;


  }

  & input {
    max-width: 140px;
    background: transparent;
    border: none;
    color: $white;
    font-size: 14px;
    font-style: italic;
    letter-spacing: -0.2px;
    line-height: 18px;
    margin-left: 10px;

    &:focus {
      outline: none;
    }

    @include media-up(mobile-s) {
      max-width: none;
    }

  }
}

.table-action-bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  float: right;
  margin: 10px 0;

  &.show {
    visibility: visible;
  }

  &.hide {
    visibility: hidden;
  }

  .a-btn {
    margin: 15px 5px;
  }
}

.table-action-bar-item {
  cursor: pointer;
  padding: 5px;
}

.table-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  white-space: nowrap;
}

.table-array {
  min-width: 250px;
}

.table-filters-bar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: $white;
  position: relative;

  .close {
    margin-top: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.table-filter {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;

  .label {
    margin-right: 15px;
  }

  .a-input-group-date, .a-input-group {
    width: 220px;
  }

  .a-input-group {
    padding-bottom: 0;
  }
}