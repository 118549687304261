.m-no-active-package {

    display: flex;
    flex-direction: column;
    align-items: center;

    .no-active-img {
        width: 185px;
        height: 200px;
        margin-top: 30px;
        background: url('../../../img/icons/drag&drop-illustration.svg') no-repeat;
        background-position: center;
        background-size: contain;
    }

    .title {
        font-size: 24px;
        line-height: 26px;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin: 30px 0 20px;
    }

    .description {
        font-size: 16px;
        line-height: 26px;
        opacity: 0.7;
        margin-bottom: 40px;
        text-align: center;
    }
}