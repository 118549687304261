.m-array-field {

    &.-array {
        list-style: none;
        padding: 0;
        width: 100%;

        // @include media-up(l) {
        //     width: 80%;
        // }

        .search-input {
            min-width: 235px;

            // @include media-up(m) {
            //     min-width: 40%;
            // }
        }


        .fld {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap-reverse;
            width: 100%;

            .date-time,
            .discount {
                min-width: 235px;
                margin-top: 20px;
            }

            .date-time-input {
                margin-bottom: 0;
            }

            .status {
                min-width: 235px;
                margin-right: 30px;
            }
        }

        li {
            margin-bottom: 25px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            // justify-content: space-between;
            color: $white;

            .info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // min-width: 320px;
                width: 100%;
                margin-left: 30px;

                // @include media-up(m) {
                //     min-width: 50%;
                // }

                .item {
                    display: flex;
                    align-items: center;
                }

                .image {
                    width: 26px;
                    height: 26px;

                    &.-service-plan {
                        width: 40px;
                        height: 40px;
                    }

                    &.-user {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                    }
                }

                .none {
                    opacity: 0;
                }

                .name {
                    margin-left: 5px;
                    margin-right: 50px;
                    cursor: pointer;
                    white-space: nowrap;
                }

                // .status {
                //     background-color: $white-100;
                //     border-radius: 22px;
                //     padding: 10px 15px;
                //     text-transform: uppercase;
                //     font-size: 10px;
                //     line-height: 13px;
                //     letter-spacing: 2px;
                // }

                .check-container {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    margin-right: 50px;

                    .check {
                        width: 10px;
                        height: 5px;
                        border: solid $purple-300;
                        border-width: 2px 2px 0 0;
                        display: inline-block;
                        padding: 1px;
                        transform: rotate(135deg);
                        margin-right: 10px;
                    }

                    .label {
                        opacity: 0.5;
                    }

                    &.-checked {
                        cursor: default;

                        .check {
                            border-color: $turquoise;
                        }
                    }
                }
            }

            .remove {
                width: 20px;
                height: 20px;
                margin-left: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    &::after {
                        background-color: rgba($white, 0.6);
                    }

                    &::before {
                        background-color: rgba($white, 0.6);
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 2px;
                    background-color: $purple-300;
                    transform: rotate(45deg);
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 20px;
                    background-color: $purple-300;
                    transform: rotate(-135deg);
                }
            }

            .booking-container {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                background: $sidebar-gradient-reversed;
                box-shadow: 0 30px 30px -10px rgba(0, 0, 0, 0.15);
                border-radius: $border-radius-extra-big;
                color: $white;
                padding: 20px;
                width: 100%;
                min-width: fit-content;
            }

            .booking-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
            }

            .field {
                display: flex;
                align-items: center;
                width: 100%;
            }

            .btn-group {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                input {
                    -webkit-appearance: none;
                }

                label {
                    padding: 8px 20px;
                    border-radius: 30px;
                    background-color: rgba($white, .1);
                    border: 1px solid rgba($white, .15);
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.3px;
                    white-space: nowrap;
                    color: $white;
                    opacity: 0.3;
                    cursor: pointer;
                    margin-bottom: 5px;
                    margin-left: 5px;

                    .price {
                        margin-left: 5px;
                    }
                }

                input:checked+label {
                    background: $blue-gradient;
                    color: $white;
                    opacity: 1;
                }
            }
        }
    }

    // &.-field {
    //     list-style: none;
    //     padding: 0;
    //     width: 100%;

    //     .search-input {
    //         min-width: 250px;
    //     }

    //     .title {
    //         color: $white;
    //         margin-bottom: 20px;
    //     }

    //     .li {
    //         display: flex;
    //         align-items: center;
    //         justify-content: flex-start;
    //         color: $white;

    //         .info {
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between;
    //             margin-left: 30px;

    //             .item {
    //                 display: flex
    //             }

    //             img {
    //                 width: 26px;
    //                 height: 26px;
    //                 border-radius: 50%;
    //             }

    //             .none {
    //                 opacity: 0;
    //             }

    //             .name {
    //                 margin-left: 5px;
    //                 margin-right: 50px;
    //                 cursor: pointer;
    //                 white-space: nowrap;
    //             }

    //             .status {
    //                 background-color: $white-100;
    //                 border-radius: 22px;
    //                 padding: 10px 15px;
    //                 text-transform: uppercase;
    //                 font-size: 10px;
    //                 line-height: 13px;
    //                 letter-spacing: 2px;
    //             }
    //         }
    //     }
    // }
}